<template>
  <div
    class="soh--shape-overlay-hero relative h-auto bg-blue opacity-0 transition-all ease-out md:h-[640px]"
    :class="{
      'video-loaded': videoLoaded,
    }"
  >
    <div class="relative flex h-full w-full flex-col-reverse">
      <div
        class="content justify-right pointer-events-none relative z-40 flex h-full items-center md:w-[500px] lg:w-full"
      >
        <slot></slot>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="621"
          height="690"
          viewBox="0 0 621 690"
          fill="none"
          class="animation-shape absolute left-0 top-0 z-20 h-full md:w-full lg:w-auto"
        >
          <path
            d="M-390 413.5C-390 134.32 -163.68 -92 115.5 -92C394.68 -92 621 134.32 621 413.5V927L-390 927L-390 413.5Z"
            fill="#0384FB"
          ></path>
        </svg>
      </div>
      <div
        class="soh--bg-block animation-shape absolute bottom-0 left-0 top-0 z-30 hidden h-full bg-blue md:block"
      ></div>
      <template v-if="hero.backgroundImage && contentType === 'video'">
        <div
          class="desktop-video relative bottom-0 right-0 top-0 block h-[237px] w-full md:absolute md:h-full"
        >
          <VideosTheVideo
            :video="hero.backgroundImage.fields"
            class="w-full"
            :lazy="false"
            @video-loaded="mp4VideoLoaded"
          ></VideosTheVideo>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
  import { provide } from 'vue';

  export default {
    props: {
      hero: {
        type: Object,
        required: true,
      },
    },
    setup() {
      const videoLoaded = ref(false);

      provide('isHeroVideo', true);

      return {
        videoLoaded,
      };
    },
    computed: {
      contentType() {
        return this.hero.backgroundImage &&
          this.hero.backgroundImage.sys.contentType.sys.id
          ? this.hero.backgroundImage.sys.contentType.sys.id
          : false;
      },
    },
    watch: {
      videoLoaded(newValue) {
        return newValue;
      },
    },
    created() {
      this.$emitter.on('video-loaded', () => {
        this.videoLoaded = true;
      });
    },
    mounted() {
      if (this.hero.backgroundImage && this.contentType === 'video') {
        if (this.hero.backgroundImage.fields.videoControls) {
          window.addEventListener('resize', this.onWindowResize);
          this.onWindowResize();
          this.positionVideoControls();
        }
      }
    },
    methods: {
      onWindowResize() {
        this.positionVideoControls();
      },
      positionVideoControls() {
        if (document.querySelector('.soh--shape-overlay-hero .column')) {
          if (window.innerWidth <= 768) {
            document.querySelector(
              '.soh--shape-overlay-hero .video--controls-button'
            ).style.left = null;
            document.querySelector(
              '.soh--shape-overlay-hero .video--controls-button'
            ).style.right = '40px';
          } else {
            document.querySelector(
              '.soh--shape-overlay-hero .video--controls-button'
            ).style.left = `${parseInt(
              document
                .querySelector('.soh--shape-overlay-hero .animation-shape')
                .getBoundingClientRect().width +
                document
                  .querySelector('.soh--shape-overlay-hero .animation-shape')
                  .getBoundingClientRect().left +
                40
            )}px`;
          }
        }
      },
      mp4VideoLoaded() {
        this.videoLoaded = true;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .soh--shape-overlay-hero {
    .content {
      @apply max-w-[1440px] md:m-0 lg:m-auto;
    }
    &.video-loaded {
      @apply animate-fade-in;
    }
    .soh--bg-block {
      @apply w-[15vw];
    }
    @media (min-width: 1440px) {
      .soh--bg-block {
        @apply w-[25vw];
      }
    }

    @media (min-width: 1920px) {
      .soh--bg-block {
        @apply w-[32vw];
      }
    }
    :deep(.video),
    :deep(.video__container),
    :deep(.video__container) video {
      @apply h-full;
    }

    :deep(.video__container) {
      video {
        @apply w-full object-cover;
      }
    }

    :deep(.column) {
      @apply z-30 ml-gap2 h-auto  bg-blue md:ml-1/12 md:bg-transparent lg:ml-0;
      h1,
      h2,
      h3 {
        @apply pt-3 text-white md:pr-0 md:pt-0;
      }

      p {
        @apply pr-0 text-white md:mb-[40px] md:pr-4;
      }

      @media (min-width: theme('screens.md')) {
        @apply w-[415px];
      }

      @media (min-width: theme('screens.lg')) {
        @apply w-[530px] lg:pl-[6.333%];
      }
    }
  }
</style>
